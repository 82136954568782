import { z } from "zod";

export const CALCULATION_TYPES = [
  "NAV + P/L",
  "NAV + P/L + Adjustment",
] as const;
export const FEE_TYPES = ["fixed", "percentage"] as const;
export const MGT_FEE_TYPES = ["fixed", "percentage", "scale"] as const;
export const FEE_TYPE_MAP = {
  fixed: "$",
  percentage: "%",
} as const;
export const MAX_PERCENTAGE_FEE = 100;
export const MAX_PERCENTAGE_FEE_MESSAGE =
  "Fee percentage must be less than 100%";
export const RATE_TYPES = ["RBA", "BBSW"] as const;

export const EditFormSchema = z
  .object({
    fees: z.object({
      nonPerformance: z.object({
        calculationType: z
          .string()
          .refine((calcType) =>
            CALCULATION_TYPES.toString().includes(calcType),
          ),
        reFee: z.preprocess(
          (value) => +(value as string),
          z.number().positive(),
        ),
        reFeeType: z
          .string()
          .refine((reFeeType) => FEE_TYPES.toString().includes(reFeeType)),
        managementFee: z.preprocess(
          (value) => +(value as string),
          z.number().nonnegative(),
        ),
        managementFeeType: z
          .string()
          .refine((managementFeeType) =>
            MGT_FEE_TYPES.toString().includes(managementFeeType),
          ),
        adminFee: z.preprocess(
          (value) => +(value as string),
          z.number().positive(),
        ),
        adminFeeType: z
          .string()
          .refine((adminFeeType) =>
            FEE_TYPES.toString().includes(adminFeeType),
          ),
        registryFee: z.preprocess(
          (value) => +(value as string),
          z.number().nonnegative(),
        ),
        registryFeeType: z
          .string()
          .refine((registryFeeType) =>
            FEE_TYPES.toString().includes(registryFeeType),
          ),
        ieeFee: z.preprocess(
          (value) => +(value as string),
          z.number().nonnegative(),
        ),
        ieeFeeType: z
          .string()
          .refine((ieeFeeType) => FEE_TYPES.toString().includes(ieeFeeType)),
        imfFee: z.preprocess(
          (value) => +(value as string),
          z.number().nonnegative(),
        ),
        imfFeeType: z
          .string()
          .refine((imfFeeType) => FEE_TYPES.toString().includes(imfFeeType)),
      }),
      performance: z.object({
        rateType: z
          .string()
          .refine((rateType) => RATE_TYPES.toString().includes(rateType)),
        marginAboveRate: z.preprocess(
          (value) => +(value as string),
          z.number().nonnegative(),
        ),
        returnAboveHurdle: z.preprocess(
          (value) => +(value as string),
          z.number().nonnegative(),
        ),
        performanceFeeFloorHurdle: z.preprocess(
          (value) => +(value as string),
          z.number().nonnegative(),
        ),
        allowanceForRitc: z.preprocess(
          (value) => +(value as string),
          z.number().nonnegative(),
        ),
        crystalisedPerformanceFee: z.preprocess(
          (value) => +(value as string),
          z.number().nonnegative(),
        ),
        performanceFeeApplicable: z.boolean(),
      }),
    }),
    previousPeriodInputs: z.object({
      closingDate: z.preprocess((value) => {
        const date = new Date(value as string);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, "0");
        const day = String(date.getDate()).padStart(2, "0");
        return `${year}-${month}-${day}`;
      }, z.string()),
      closingNav: z.preprocess(
        (value) => +(value as string),
        z.number().positive(),
      ),
      applicationUnits: z.preprocess(
        (value) => +(value as string),
        z.number().nonnegative(),
      ),
      redemptionUnits: z.preprocess(
        (value) => +(value as string),
        z.number().nonnegative(),
      ),
      navUnitPrice: z.preprocess(
        (value) => +(value as string),
        z.number().nonnegative(),
      ),
      capOnPerformanceFee: z.preprocess(
        (value) => +(value as string),
        z.number().nonnegative(),
      ),
      periodUnderPerformance: z.preprocess(
        (value) => +(value as string),
        z.number().nonnegative(),
      ),
    }),
    fundInformation: z.object({
      name: z.string(),
      code: z.string(),
      preparedBy: z.string(),
      apir: z.string(),
    }),
  })
  .refine(
    (data) =>
      data.fees.nonPerformance.reFeeType !== "percentage" ||
      data.fees.nonPerformance.reFee < MAX_PERCENTAGE_FEE,
    {
      message: MAX_PERCENTAGE_FEE_MESSAGE,
      path: ["fees", "nonPerformance", "reFee"],
    },
  )
  .refine(
    (data) =>
      data.fees.nonPerformance.managementFeeType !== "percentage" ||
      data.fees.nonPerformance.managementFee < MAX_PERCENTAGE_FEE,
    {
      message: MAX_PERCENTAGE_FEE_MESSAGE,
      path: ["fees", "nonPerformance", "managementFee"],
    },
  )
  .refine(
    (data) =>
      data.fees.nonPerformance.adminFeeType !== "percentage" ||
      data.fees.nonPerformance.adminFee < MAX_PERCENTAGE_FEE,
    {
      message: MAX_PERCENTAGE_FEE_MESSAGE,
      path: ["fees", "nonPerformance", "adminFee"],
    },
  )
  .refine(
    (data) =>
      data.fees.nonPerformance.registryFeeType !== "percentage" ||
      data.fees.nonPerformance.registryFee < MAX_PERCENTAGE_FEE,
    {
      message: MAX_PERCENTAGE_FEE_MESSAGE,
      path: ["fees", "nonPerformance", "registryFee"],
    },
  )
  .refine(
    (data) =>
      data.fees.nonPerformance.ieeFeeType !== "percentage" ||
      data.fees.nonPerformance.ieeFee < MAX_PERCENTAGE_FEE,
    {
      message: MAX_PERCENTAGE_FEE_MESSAGE,
      path: ["fees", "nonPerformance", "ieeFee"],
    },
  )
  .refine(
    (data) =>
      data.fees.nonPerformance.imfFeeType !== "percentage" ||
      data.fees.nonPerformance.imfFee < MAX_PERCENTAGE_FEE,
    {
      message: MAX_PERCENTAGE_FEE_MESSAGE,
      path: ["fees", "nonPerformance", "imfFee"],
    },
  );
