// @mui
import {
  FormControl,
  Chip,
  IconButton,
  Button,
  Box,
  Tooltip, MenuItem,
} from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import { Page } from "../../layouts/components";
import moment from "moment";
// REDUX STORE
import { useAppDispatch, useAppSelector } from "../../store/store";
import { roundToDecimal } from "../../utils/mathUtil";
import PageTitle from "../../components/PageTitle/PageTitle";
import BorderedContainer from "../../components/BorderedContainer/BorderedContainer";
import ControlBar from "../../components/ControlBar/ControlBar";
import ControlBarSection from "../../components/ControlBarSection/ControlBarSection";
import React, { useState } from "react";
import SearchControl from "../../components/SearchControl/SearchControl";
import UnitPriceDataGrid from "../../components/DataGrid/DataGrid";
import { StyledButton } from "../../components/StyledButton/StyledButton";
import { StyledFormControl } from "../../components/StyledFormControl/StyledFormControl";
import RunCreationModal from "../../forms/RunCreation/RunCreationModal";
import ArticleIcon from "@mui/icons-material/Article";
import AddIcon from "@mui/icons-material/Add";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import DownloadIcon from "@mui/icons-material/Download";
import AuditReportModal from "../../components/AuditReportModal/AuditReportModal";
import { getAuditReport, getRuns } from "../../store/run/runFunction";
import { Run } from "../../models/run";
import StyledDatePicker from "../../components/StyledDatePicker/StyledDatePicker";
import { convertToDateString } from "../../utils/DateUtils";
import CancelRunModal from "../../forms/RunApproval/CancelRunModal";
import ApproveRunModal from "../../forms/RunApproval/ApproveRunModal";
import { getImdDownload } from "../../store/imd/imdFunctions";
import { handleDownloadFile } from "../../utils/fileUtils";
import StyledSelect from "../../components/StyledSelect/StyledSelect";

// Process Runs
export default function Runs() {
  const actions = useAppSelector((state) =>
    state.user.user?.permissions.actions.map((a) => a.permission),
  );
  const username = useAppSelector((state) => state.user.user?.displayName);

  const ActionButtons = ({
    handleOpenAuditReportModal,
    handleOpenCancelRunModal,
    handleOpenApproveRunModal,
    runId,
    createdBy,
    status,
  }: {
    handleOpenAuditReportModal: (runs: Array<Run>) => void;
    handleOpenCancelRunModal: (id: number) => void;
    handleOpenApproveRunModal: (id: number) => void;
    runId: number;
    createdBy: string;
    status: string;
  }) => {
    const [openAuditReportHover, setOpenAuditReportHover] = useState(false);
    const [openApprovePriceHover, setOpenApprovePriceHover] = useState(false);
    const [openCancelRunHover, setOpenCancelRunHover] = useState(false);
    const [downloadImdHover, setDownloadImdHover] = useState(false);
    const dispatch = useAppDispatch();

    // Handle IMD file download on client
    const handleImdDownload = async () => {
      const res: any = await dispatch(getImdDownload(runId));
      if (!res.error) {
        handleDownloadFile(`imd_${runId}.xlsx`, res.payload.data);
      }
    };

    return (
      <Box>
        <Tooltip title="Open Audit Report">
          <IconButton
            size="small"
            disableRipple
            onMouseEnter={() => setOpenAuditReportHover(true)}
            onMouseLeave={() => setOpenAuditReportHover(false)}
            onClick={async () => {
              const data = await dispatch(getAuditReport(runId));
              handleOpenAuditReportModal(data.payload);
            }}
          >
            <ArticleIcon
              style={{ color: openAuditReportHover ? "#2040cd" : "#BBBBBB" }}
              fontSize="small"
            />
          </IconButton>
        </Tooltip>
        {(status === "Pending" || status === "Completed") && (
          <Tooltip title="Download IMD">
            <IconButton
              size="small"
              disableRipple
              onMouseEnter={() => setDownloadImdHover(true)}
              onMouseLeave={() => setDownloadImdHover(false)}
              onClick={async () => {
                await handleImdDownload();
              }}
            >
              <DownloadIcon
                style={{ color: downloadImdHover ? "#2040cd" : "#BBBBBB" }}
                fontSize="small"
              />
            </IconButton>
          </Tooltip>
        )}
        {status === "Pending" &&
          actions &&
          actions.some((a) => a === "up_edit_all") && (
            <>
              {createdBy !== username && (
                <Tooltip title="Approve Pricing Run">
                  <IconButton
                    size="small"
                    disableRipple
                    onMouseEnter={() => setOpenApprovePriceHover(true)}
                    onMouseLeave={() => setOpenApprovePriceHover(false)}
                    onClick={async () => {
                      handleOpenApproveRunModal(runId);
                    }}
                  >
                    <DoneIcon
                      style={{
                        color: openApprovePriceHover ? "#00A638" : "#BBBBBB",
                      }}
                      fontSize="small"
                    />
                  </IconButton>
                </Tooltip>
              )}
              <Tooltip title="Cancel Pricing Run">
                <IconButton
                  size="small"
                  disableRipple
                  onMouseEnter={() => setOpenCancelRunHover(true)}
                  onMouseLeave={() => setOpenCancelRunHover(false)}
                  onClick={() => {
                    handleOpenCancelRunModal(runId);
                  }}
                >
                  <CloseIcon
                    style={{
                      color: openCancelRunHover
                        ? "rgba(255,0,0,0.64)"
                        : "#BBBBBB",
                    }}
                    fontSize="small"
                  />
                </IconButton>
              </Tooltip>
            </>
          )}
      </Box>
    );
  };

  // -------------------------------------------------------------------------------------------------------------
  // State management for Audit Report Modal
  const [openAuditReportModal, setOpenAuditReportModal] = React.useState(false);
  const [auditReportData, setAuditReportData] = React.useState<Array<Run>>([]);
  const handleCloseAuditReportModal = () => {
    if (auditReportData.length) setAuditReportData([]);
    setOpenAuditReportModal(false);
  };
  const handleOpenAuditReportModal = (runs: Array<Run>) => {
    if (runs.length) setAuditReportData(runs);
    setOpenAuditReportModal(true);
  };
  // -------------------------------------------------------------------------------------------------------------
  // State management for Cancel Run Modal
  const [openCancelRunModal, setCancelRunModal] = React.useState(false);
  const [cancelRunId, setCancelRunId] = React.useState<number>(-1);
  const handleOpenCancelRunModal = (runId: number) => {
    setCancelRunId(runId);
    setCancelRunModal(true);
  };
  const handleCloseCancelRunModal = () => setCancelRunModal(false);
  // -------------------------------------------------------------------------------------------------------------
  // State management for Approve Run Modal
  const [openApproveRunModal, setApproveRunModal] = React.useState(false);
  const [approveRunId, setApproveRunId] = React.useState<number>(-1);
  const handleOpenApproveRunModal = (runId: number) => {
    setApproveRunId(runId);
    setApproveRunModal(true);
  };
  const handleCloseApproveRunModal = () => setApproveRunModal(false);
  // -------------------------------------------------------------------------------------------------------------

  let columns: GridColDef[] = [
    {
      field: "fundCode",
      headerName: "Fund",
      flex: 1,
      valueGetter: (params) => {
        return params.row.fund.code;
      },
    },
    {
      field: "modelDate",
      headerName: "Date",
      flex: 1,
    },
    {
      field: "unitPrice",
      headerName: "Unit Price",
      flex: 1,
      valueGetter: (params) => {
        return roundToDecimal(
          params.row.metadata.unitPriceModelOutputs.unitPricePostPerfFees,
          4,
        );
      },
    },
    {
      field: "createdBy",
      headerName: "Prepared By",
      flex: 1,
    },
    {
      field: "createdTimestamp",
      headerName: "Created On",
      flex: 1,
      valueFormatter: (params) =>
        moment(params.value).format("DD MMM YY, HH:mm"),
    },
    {
      field: "approvedBy",
      headerName: "Approver",
      flex: 1,
      valueFormatter: (params) => (params.value ? params.value : "-"),
    },
    {
      field: "approvedTimestamp",
      headerName: "Approved On",
      flex: 1,
      valueFormatter: (params) =>
        params.value ? moment(params.value).format("DD MMM YY, HH:mm") : "-",
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      renderCell: (params) => {
        switch (params.value) {
          case "Completed":
            return (
              <Chip
                label={params.value}
                variant="outlined"
                sx={{
                  color: "#00A638",
                  backgroundColor: "#e6f6eb",
                  outlineColor: "#00A638",
                  outline: "1px solid",
                  fontSize: "8pt",
                  width: "80px",
                }}
                size="small"
              />
            );
          case "Pending":
            return (
              <Chip
                label={params.value}
                variant="outlined"
                sx={{
                  color: "#1D4ED8",
                  backgroundColor: "#EFF6FF",
                  outlineColor: "#1D4ED8",
                  outline: "1px solid",
                  fontSize: "8pt",
                  width: "80px",
                }}
                size="small"
              />
            );
          case "Cancelled":
            return (
              <Chip
                label={params.value}
                variant="outlined"
                sx={{
                  color: "#FF5C00",
                  backgroundColor: "#FFF4ED",
                  outlineColor: "#FF5C00",
                  outline: "1px solid",
                  fontSize: "8pt",
                  width: "80px",
                }}
                size="small"
              />
            );
          default:
            return "ERR";
        }
      },
    },
    {
      field: "actions",
      headerName: "",
      minWidth: 140,
      renderCell: (params) => (
        <ActionButtons
          handleOpenAuditReportModal={handleOpenAuditReportModal}
          handleOpenCancelRunModal={handleOpenCancelRunModal}
          handleOpenApproveRunModal={handleOpenApproveRunModal}
          runId={params.row.id}
          createdBy={params.row.createdBy}
          status={params.row.status}
        />
      ),
    },
  ];

  const [searchText, setSearchText] = useState("");
  const [selectedStatus, setSelectedStatus] = useState<string>("");
  const [selectedDate, setSelectedDate] = React.useState<Date | null>(
    new Date(),
  );

  // ----------------------------------------------------------------------------------------------
  // State management for Run Creation Modal
  const [openRunModal, setOpenRunModal] = React.useState(false);
  const handleOpenEditModal = () => setOpenRunModal(true);
  const handleCloseEditModal = () => setOpenRunModal(false);
  // ----------------------------------------------------------------------------------------------

  const dispatch = useAppDispatch();

  const runs = useAppSelector((state) => state.runs.runs);
  const filteredRuns = runs.filter((run) => {
    const text = searchText.toLowerCase();
    if (run.status === selectedStatus || selectedStatus === "") {
      if (run.fund.code.toLowerCase().includes(text)) {
        // Fund code
        return true;
      }
      if (run.approvedBy?.toLowerCase().includes(text)) {
        // Approved By
        return true;
      }
      return run.createdBy.toLowerCase().includes(text);
    }
    return false;
  });

  return (
    <Page title="Runs">
      <PageTitle title="Runs" />
      <BorderedContainer>
        <ControlBar>
          <ControlBarSection>
            <ControlBarSection>
              <StyledFormControl fullWidth size="small">
                <StyledSelect
                    label="Status"
                    displayEmpty
                    value={selectedStatus}
                    onChange={(e) => setSelectedStatus(e.target.value as string)}>
                  <MenuItem value="">All</MenuItem>
                  <MenuItem value="Pending">Pending</MenuItem>
                  <MenuItem value="Completed">Completed</MenuItem>
                  <MenuItem value="Cancelled">Cancelled</MenuItem>
                </StyledSelect>
              </StyledFormControl>
            </ControlBarSection>
            <FormControl
              fullWidth
              size="small"
              sx={{ paddingTop: "0px", minWidth: "140px", maxWidth: "140px" }}
            >
              <StyledDatePicker
                value={selectedDate}
                onChange={async (event: Date) => {
                  setSelectedDate(event);
                  await dispatch(getRuns(convertToDateString(event)));
                }}
              />
            </FormControl>
            <FormControl
              fullWidth
              size="small"
              sx={{ minWidth: "60px", maxWidth: "60px" }}
            >
              <Button
                onClick={async () => {
                  setSelectedDate(new Date());
                  setSelectedStatus("");
                  await dispatch(getRuns(null));
                }}
                disabled={false}
              >
                Reset
              </Button>
            </FormControl>
          </ControlBarSection>
          <ControlBarSection>
            <SearchControl
              searchText={searchText}
              setSearchText={setSearchText}
            />
            {actions && actions.some((a) => a === "up_edit_all") && (
              <StyledFormControl fullWidth>
                <StyledButton
                  variant="contained"
                  onClick={() => handleOpenEditModal()}
                  disabled={false}
                >
                  <AddIcon fontSize="small" />
                  New Run
                </StyledButton>
              </StyledFormControl>
            )}
          </ControlBarSection>
        </ControlBar>
        <UnitPriceDataGrid columns={columns} rows={filteredRuns} />
      </BorderedContainer>
      <RunCreationModal
        open={openRunModal}
        handleClose={handleCloseEditModal}
      />
      <AuditReportModal
        open={openAuditReportModal}
        handleClose={handleCloseAuditReportModal}
        runs={auditReportData}
      />
      <CancelRunModal
        open={openCancelRunModal}
        handleClose={handleCloseCancelRunModal}
        runId={cancelRunId}
      />
      <ApproveRunModal
        open={openApproveRunModal}
        handleClose={handleCloseApproveRunModal}
        runId={approveRunId}
      />
    </Page>
  );
}
